ion-modal {
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.4);

  ion-content {
    --background: #fff !important;
    font-family: 'Poppins', sans-serif;
  }

  ion-footer {
    ion-toolbar {
      --min-height: 45px !important;

      ion-buttons {
        ion-button {
          --border-radius: 4px !important;
          font-size: 13px !important;
          height: 32px !important;
          --padding-start: 1.1em !important;
          --padding-end: 1.1em !important;
        }
      }
    }
  }
}

.support-modal::part(content) {
  --border-radius: 5px;
  margin: 0 10px;
  --height: 225px;

  ion-header::after {
    background: none !important;
    border-bottom: 1px solid #d3d3d38c;
  }

  ion-header {
    ion-toolbar {
      ion-buttons {
        button {
          border: 0px !important;
          font-size: 20px;
          margin: 4px 7px 0;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    margin: 0px;
    --width: 100%;
    --height: 100%;
  }
}
.position-pnl-modal::part(content) {
  --border-radius: 5px;
  margin: 0 10px;
  --height: 300px;

  ion-header::after {
    background: none !important;
    border-bottom: 1px solid #d3d3d38c;
  }

  ion-header {
    ion-toolbar {
      ion-buttons {
        button {
          border: 0px !important;
          font-size: 20px;
          margin: 4px 7px 0;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    margin: 0px;
    --width: 100%;
    --height: 100%;
  }
}
.under-construction::part(content) {
  --border-radius: 10px;
  margin: 0 10px;
  --height: 361px;

  ion-header::after {
    background: none !important;
    border-bottom: 1px solid #d3d3d38c;
  }

  ion-header {
    ion-toolbar {
      ion-buttons {
        button {
          border: 0px !important;
          font-size: 20px;
          margin: 4px 7px 0;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    margin: 0px;
    --width: 100%;
    --height: 100%;
  }
}
.email-broadcast-platform-switch-modal::part(content) {
  --border-radius: 5px;
  margin: 0 10px;
  --height: 250px;

  @media screen and (max-width: 768px) {
    margin: 0px;
    --width: 100%;
    --height: 100%;
  }
}

.broadcast-preview-modal::part(content) {
  max-width: 1500px;
  --width: 90%;
  --height: 90%;
  margin: 0 auto;

  @media screen and (max-width: 768px) {
    --width: 100%;
    --height: 100%;
  }
}

.chat-aggrement-modal::part(content),
.product-group-modal::part(content),
.chat-setting-modal::part(content),
.channel-model::part(content),
.server-logs-details-modal::part(content),
.pdf-viewer-modal::part(content) {
  max-width: 800px;
  --width: 80%;
  --height: 80%;
  margin: 0 auto;

  @media screen and (max-width: 768px) {
    --width: 100%;
    --height: 100%;
  }
}

.gallery-manager-modal::part(content) {
  --height: 80%;
  --width: 80%;
  max-width: 1200px;
  margin: 0 auto;

  @media screen and (max-width: 768px) {
    --width: 100%;
    --height: 100%;
  }
}

.layerd-modal-backdrop {
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4) !important;
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.32) !important;
}

.broadcast-platform-switch-container {
  display: flex;
  align-items: center;
  gap: 13px;

  .svg-container {
    width: 40px;
    height: 20px;
  }

  .right-svg-container {
    width: 22px;
    height: auto;
  }

  .checkbox-container {
    position: relative;
    width: 40px;
    height: 20px;

    .switch-checkbox {
      opacity: 0;
      position: relative;
      cursor: pointer;
      z-index: 1;
      width: 100%;
      height: 100%;
    }

    .switch-checkbox:checked + .switch-label {
      background-color: var(--ion-color-primary);
    }

    .switch-checkbox:checked + .switch-label:before {
      transform: translateX(20px);
    }

    .switch-label {
      position: absolute;
      top: 0;
      left: 0;
      width: 40px;
      height: 20px;
      border-radius: 10px;
      background-color: #ccc;
      cursor: pointer;
    }

    .switch-label:before {
      content: '';
      position: absolute;
      top: 2px;
      left: 2px;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background-color: #fff;
      transition: transform 0.3s ease;
    }
  }
}

.modal-buyers-information {
  --width: 57%;
  .datatable-body-row,
  .datatable-scroll,
  .datatable-row-group {
    width: 100% !important;
  }
}
