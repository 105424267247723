/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import '~@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';

/* Fonts */
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Oswald:wght@200;300;400;500;600;700&display=swap');

/* Global Styles */
@import './classes.scss';
@import './gallery.scss';
@import './insider-chat.scss';
@import './custom-buttons.scss';
@import './datatable.scss';
@import './editor.scss';
@import './modals.scss';
@import './notification.scss';
@import './dropdown-menu-component.scss';

* {
  font-family: 'Poppins', sans-serif;
}

ion-content {
  --background: var(--ion-color-light);
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span {
  font-family: 'Poppins', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

.f-400 {
  font-weight: 400;
  font-family: 'Poppins', sans-serif;
}

.f-600 {
  font-weight: 600;
  font-family: 'Poppins', sans-serif;
  text-transform: uppercase;
}

.my-custom-class .popover-content {
  width: auto;
}

.header-notification-popover {
  margin-left: -20px;
  --min-width: 400px;
  --box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  @media (max-width: 380px) {
    --min-width: 300px !important;
    left: 20px !important;
  }

  app-header-notifications-popover {
    &::after {
      left: 3px;
      top: 3px;
      border-radius: 3px;
      position: absolute;
      width: 14px;
      height: 14px;
      transform: rotate(45deg);
      background: var(--background);
      content: '';
      z-index: 10;
    }
    ion-content {
      --background: #fff;

      .header-notification {
        .notification-header {
          display: flex;
          justify-content: space-between;
          border-bottom: 1px solid lightgrey;
          padding: 15px 20px;
          align-items: center;

          .header-title {
            font-weight: bolder;
            color: var(--ion-color-medium-shade);
          }

          .header-action {
            span {
              color: var(--ion-color-primary);
              font-weight: 600;
              font-size: 15px;
              cursor: pointer;
            }
          }
        }

        .notification-body {
          max-height: 500px;
          height: auto;
          overflow-y: auto;

          &::-webkit-scrollbar {
            display: none;
          }
          .notification {
            .left-section {
              align-items: center;
              max-width: 100% !important;
              .user-profile {
                min-width: 41px !important;
              }
            }
          }
        }

        .notification-footer {
          padding: 1px 3px;
        }
      }
    }
  }
}

.header-profilemenu-popover {
  margin-left: -20px;
  --min-width: 230px;
  --box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;

  app-header-profilemenu-popover {
    ion-content {
      --background: #fff;

      .profile-setting-dropdown {
        list-style-type: none;
        padding: 0px;
        width: auto;
        height: auto;
        margin: 0;

        .dropdown-header {
          text-align: center;
          font-size: 15px;
          padding: 15px 25px;
          border-bottom: 1px solid lightgrey;

          h6 {
            font-size: 16px;
            font-weight: 600;
            margin: 0;
            color: var(--ion-color-medium);
          }

          span {
            ion-badge {
              margin-top: 3px;
              color: var(--ion-color-warning-contrast);
            }
          }
        }

        .dropdown-menu-item {
          font-family: 'Poppins', sans-serif;
          cursor: pointer;
          padding: 10px 15px;
          transition: 0.3s;
          align-items: center;
          display: flex;
          background-color: transparent;

          &:hover {
            background: var(--ion-color-primary-tint);
            color: var(--ion-color-primary-contrast);
          }

          ion-icon {
            margin-right: 12px;
            font-size: 22px;
          }

          span {
            font-size: 14.5px;
          }
        }

        .dropdown-menu-item:not(:last-of-type) {
          border-bottom: 1px solid lightgrey;
        }

        &::before {
          content: '';
          width: 13px;
          height: 13px;
          background: #fff;
          position: absolute;
          top: -7px;
          right: 20px;
          transform: rotate(45deg);
          border-top: 1px solid #eaedf1;
          border-left: 1px solid #eaedf1;
        }
      }
    }
  }
}

.menu-content-open {
  pointer-events: inherit;
}

.video-wrapper {
  margin: 20px 0px;
  position: relative;
  overflow: hidden;
  border: 20px solid #dae1e7;
  width: 100%;
  display: flex;

  @media (max-width: 767px) {
    border: 5px solid #dae1e7;
  }

  .video-inner,
  > iframe {
    display: flex;
    width: 100% !important;
    height: auto !important;
    aspect-ratio: 16 / 9;
    min-height: auto !important;
  }

  .video-inner {
    display: flex;

    @media (max-width: 1512px) {
      flex-direction: column;
    }

    > iframe:first-child {
      width: 61%;
      height: auto;
      aspect-ratio: 4/3;

      @media (min-width: 1401px) and (max-width: 1700px) {
        width: 62%;
      }

      @media (max-width: 1512px) {
        width: 100%;
        aspect-ratio: 16/9;
      }
    }

    > iframe:last-child {
      width: 100%;
      height: auto;
      aspect-ratio: 16/9;
    }

    > iframe:nth-child(2) {
      width: 39%;
      height: auto;
      aspect-ratio: 4/3;

      @media (min-width: 1401px) and (max-width: 1700px) {
        width: 39%;
      }

      @media (max-width: 1512px) {
        width: 100%;
        aspect-ratio: 16/9;
      }

      @media (min-width: 1180px) and (max-width: 1410px) {
        aspect-ratio: 12/9;
      }

      @media (min-width: 820px) and (max-width: 1180px) {
        aspect-ratio: 8/9;
      }

      @media (min-width: 640px) and (max-width: 768px) {
        aspect-ratio: 13/9;
      }

      @media (max-width: 480px) {
        aspect-ratio: 5/9;
      }
    }
  }
}

ion-picker {
  .picker-wrapper {
    top: 0;
  }
}

iframe {
  max-width: 100%;
  min-height: 650px !important;
  height: 100% !important;
}

.custom-alert-popup {
  .alert-input-group {
    padding: 0px 10px 15px;
  }

  .alert-wrapper {
    width: 600px !important;
    max-width: 400px !important;
    padding-bottom: 40px;
    padding: 0 24px 24px;

    @media screen and (max-width: 580px) {
      width: 90% !important;
      --min-width: 90%;
    }

    .alert-head {
      text-align: center;

      h2 {
        font-size: 30px;
      }
    }

    .alert-message {
      text-align: center;
      font-family: 'Poppins', sans-serif;
      font-size: 20px;
      padding-inline-end: 0;
      padding-inline-start: 0;

      strong {
        font-weight: 700;
      }
    }

    .alert-button-group {
      justify-content: center !important;

      button {
        margin-bottom: 7px !important;
        font-weight: 400;
      }
    }
  }
}

.text-diff {
  width: 100%;

  .td-table-wrapper {
    @media screen and (max-width: 580px) {
      width: 100% !important;
    }
  }

  .td-wrapper {
    @media screen and (max-width: 580px) {
      display: flex !important;
      flex-wrap: wrap;
      justify-content: flex-start;
    }

    .td-toolbar-select-format {
      @media screen and (max-width: 580px) {
        margin-left: 0;
      }
    }
  }
}

.search {
  --box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  --border-radius: 4px;
  margin-bottom: 15px;
  width: 33%;

  @media (max-width: 767px) {
    width: 100%;
    height: 43px;
    // --box-shadow: none;
    --background: #fff;

    .searchbar-input-container {
      height: 39px;
    }
  }
}

.widget-section {
  margin: 7px 0px 0px 0px;
  padding-top: 18px;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(3, minmax(0, 1fr));

  @media (max-width: 767px) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .item-block {
    background: transparent;
    box-shadow: rgb(0 0 0 / 20%) 0px 3px 1px -2px, rgb(0 0 0 / 14%) 0px 2px 2px 0px,
      rgb(0 0 0 / 12%) 0px 1px 5px 0px;

    .tradingview-widget-container {
      .tradingview-widget-container__widget {
        div {
          width: auto !important;

          iframe {
            box-sizing: border-box;
            min-height: 650px !important;
            height: auto !important;
            width: 100% !important;
          }
        }
      }
    }
  }
}

.custom-loader-container {
  .loading-wrapper {
    display: flex;
    flex-direction: column;
    background: var(--ion-color-primary);
    border-radius: 5px;
    padding: 40px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;

    .loading-spinner {
      ion-spinner {
        color: #fff;
      }
    }

    .loading-content {
      margin: 0;
      color: #fff;
      font-size: 15px;
      font-weight: 600;
      text-transform: capitalize;
    }
  }
}

app-read-more {
  p {
    font-size: 15px;
    margin-bottom: auto;

    @media screen and (max-width: 767px) {
      line-height: normal;
      margin-bottom: 4px;
      display: inline-block;
    }
  }
}

.login-progress {
  background: #cfe2c4;
  width: 100%;
  position: absolute;
  bottom: 5px;
}

.highlighted-word {
  color: var(--ion-color-warning-contrast);
  background: var(--ion-color-warning);
}

.template-preview {
  table {
    tbody {
      tr {
        td {
          width: auto !important;
        }
      }
    }
  }
}

.recurring-event-popover {
  --width: auto;
  --max-width: 90%;

  app-event-recurring-popover {
    ion-content {
      .popover-container {
        padding: 8px 16px;
        border-left: 6px solid var(--ion-color-primary);
        position: relative;

        div {
          span {
            color: var(--ion-color-primary);
            font-weight: 600;
          }
        }
      }
    }
  }
}

app-agreement-popup {
  ion-content {
    form {
      ion-row {
        ion-col {
          ion-item {
            ion-label {
              white-space: normal !important;
            }
          }
        }
      }
    }

    .video-wrapper {
      margin: 0;
      border: 13px solid var(--ion-color-light-shade);

      iframe {
        aspect-ratio: 16/10;
      }
    }
  }
}

.ion-icon-container {
  display: flex;
  flex-direction: column;
  user-select: none;

  virtual-scroller {
    width: 100%;
    height: 350px;
    margin: 10px 0px;
    border: 1px solid lightgray;

    .icon-list-1 {
      display: grid;
      grid-template-columns: repeat(8, 1fr);
      grid-gap: 17px 57px;
      font-size: 30px;
      cursor: pointer;
      width: 100%;
      padding: 10px;

      @media screen and (max-width: 768px) {
        grid-template-columns: repeat(5, 1fr);
      }

      @media screen and (max-width: 480px) {
        grid-template-columns: repeat(4, 1fr);
      }

      @media screen and (max-width: 360px) {
        grid-template-columns: repeat(3, 1fr);
      }
    }
  }
}

.chat-area {
  .highlited-thread-container,
  .parent-highlited-thread {
    .title {
      .message-wrapper {
        .embed-content-container {
          app-chat-files-preview {
            .ngx-link-preview-container {
              .og-link-preview-file {
                .preview-file {
                  background: transparent;
                }
              }
            }
          }
        }
      }
    }
  }
}

.product-content-root {
  .slider-container.sticky {
    app-slider {
      .swiper-wrapper {
        .swiper-slide {
          border-top-left-radius: 0px;
          border-top-right-radius: 0px;
        }
      }
    }
  }
}

.update-channels-bedge {
  display: inline-block;
  padding: 4px;
  background: var(--ion-color-primary);
  border-radius: 50%;
}

.sidebar-radio-group {
  width: 100%;

  ion-list-header {
    ion-label {
      font-size: 16px !important;
    }
  }

  ion-item {
    --min-height: 36px;

    ion-label {
      font-size: 14px !important;
    }
  }
}

.container-edit-profile {
  .image-upload-profile {
    app-image-upload {
      .ten-image-uploader {
        .picture__container {
          .picture__img {
            border-radius: unset !important;
          }
        }
      }
    }
  }
}

.highlight-text {
  background-color: yellow;
}

.span-wrapper-standalone-icon {
  padding: 0;
  margin: 0;
  line-height: 0;
  cursor: pointer;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #c3c3c3;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 8px #bdbdbd;
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

.bordered-ion-item {
  border: 1px solid lightgrey;
  border-radius: 4px;
  --min-height: 36px;
}

.emoji-picker-popover {
  --width: auto;
}

.emoji-mart {
  --width: 330px;
}

.m-0 {
  margin: 0 !important;
}
.p-0 {
  padding: 0 !important;
}
.rounded-10 {
  border-radius: 10px;
}
.gap-15 {
  gap: 15px !important;
}
.mb-10 {
  margin-bottom: 10px;
}
.overflow-x-auto {
  overflow-x: auto !important;
}
.w-full {
  width: 100%;
}
